import React from "react";
import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx } from "theme-ui";
import Img from "gatsby-image";
import { graphql } from "gatsby";

const ThisPage = ({ data, location }) => {
  const imgUrl =
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F370a22-574-f1d8-beea-55b4e6f06f0?alt=media&token=f28b5a6e-7745-4592-92c9-fecee1c4185f";
  const fullName = `Inez "Nana" Woodard Rucker`;
  const zoomUrl =
    "https://us02web.zoom.us/j/85214440856?pwd=SlBxbFAzdFFYMWRFL1o3UC9kN2RZZz09";
  const zoomPhoneNumber = "(253) 215-8782";
  const zoomMeetingId = "852 1444 0856";
  const zoomMeetingPasscode = "935076";
  const birthDate = new Date(1918, 4 - 1, 13);
  const deathDate = new Date(2021, 1 - 1, 12);
  const birthDeathFooter = "";
  const memorialDate = new Date(2021, 2 - 1, 17);
  const memorialTime = "11 AM PST";
  const inviteText = `Inez "Nana" Woodard Rucker passed away on January 12, 2021. Inez had a zest for life and loved the Lord, her family and traveling! Her amazing life will always be a shining example of how to live life to the fullest while putting the Lord first. Although we can't all be together in person to send Inez home to glory, her family invites you to a virtual service honouring her life.`;
  const inviteOverride = "";
  const obituary = `Inez Rucker, an Extraordinary Life
\nThis obituary was hand written by Inez Rucker and lovingly edited by her family.
\nOn April 13th, 1918, Inez Francis was born to Maurice and Ella Francis in Lake Charles, LA. Inez was the second child; preceded by her older brother Albert, and soon followed by her younger sister Grace. Very early in her life, the family moved to Oakland, CA and united with Beth Eden Baptist Church, which at that time was located on Filbert St., Oakland CA (and later moved to its current location on Magnolia St, Oakland). Eventually, the entire Francis family moved from Lake Charles and settled in Los Angeles and the Bay Area. Those in the Oakland area also joined Beth Eden Baptist Church under the Rev. J.P. Hubbard and family. 
\nFrom Kindergarten through the 10th grade, Inez attended Prescott School in Oakland. At age 12, she was baptized, cementing her lifelong love and devotion to her Lord and Savior.  In early 1933, Inez graduated from Jr. High School. During this year the first caissons, (a watertight retaining structure) was lowered for the foundations of the Bay Bridge. 
\nIn the fall of 1933, Inez attended Oakland Technical High School, and graduated in June 1936. All through High School Inez was active in all sports including baseball, basketball, handball, archery, tennis etc. She also loved walking. Easily and often she would walk from West Oakland 10th and Center Street, to East Oakland, East 11th street (which is about 4-5 miles), where members of her extended family lived. Inez enjoyed visiting her extended family, as the families were very close. 
  \nAfter graduating from Oakland Technical High School, Inez worked seasonally at Del Monte Cannery #35 in Emeryville for 20 years. During that time she met and married Ranshaw Berry (R.B.) Woodard and settled in Berkeley, CA. This union was blessed with Inez’s only child, Randolph (Little Man) Woodard, born at Providence Hospital in 1939.
  \nIn 1941, during WW2 Inez worked in the Oakland Shipyards, stringing cables on ships. The Woodard Family moved to Parchester Village in Richmond in 1950, where they proudly purchased a new home.   After moving to Parchester Village, she organized a social club composed of Junior High School students from the area, she named them The Sophisticated Kats and Kittens.  This was the only co-ed group that belonged to the YWCA in Richmond, CA. This club was very active, hosting dances, picnics, and other activities and outings.
  \nIn 1954, Inez returned to school and earned a Vocational Nursing License. She then went to work at Herrick Memorial Hospital in Berkeley. Later, she went into private duty nursing where her profession took her to different hospitals all around the Bay Area. 
  \nInez married Dudley Rucker in February 1979.  They both liked to travel, and together they explored the world including the U.S., Spain, Israel, England, Singapore, Caribbean, Alaska, Hawaii, Canada, France, Holland, Italy, Taipei, Hong Kong, Brazil, Rio deJaneiro  and the Panama Canal.  They enjoyed family gatherings, Cousins Picnics , serving at church, long walks, and the occasional IHOP and Burger King meals.
  \nIn the late 1970’s Inez returned to Laney College, where she obtained a Laboratory Technician License and returned to work at Alta Bates Health Testing Department. After leaving Alta Bates, she continued to work as a nurse at local hospitals until she retired  in 1981.
  \nInez was a long time member of McGee Avenue Baptist Church in Berkeley, under the leadership of Rev. James Stewart.  She served in the kitchen feeding the needy, sang in choir singing for the Lord, and was a member of S.W.A.P. (Sisters With A Purpose). In the early 1990’s Inez, along with McGee Avenue Baptist church sent several Jr. High and High school students to a once in a lifetime trip to a Baptist convention in Zimbabwe for a month. In attendance was Inez’s two youngest granddaughter’s Tiffany and Tenay.   
  \n“Mother Rucker”, as she was lovingly called, later became a member of St. John Missionary Baptist Church in Richmond under the leadership of Dr. Kevin B. Hall, Pastor.  This was her last church home. At St. John’s Inez was a member of the choir, Mother's Club, and Naomi Circle.
  \nInez was also a lifetime member of N.A.A.C.P., member of  Eastern Star Sinoda Chapter No. 49, and if there is one, an unofficial member of the Obama fan club, as she lovinging had pictures all over their apartment of her beloved 44th president and his family. 
  \nIn heaven, Nana will be greeted by her Savior, her husband Dudley Rucker, her Mother Ella and Father Maurice, her brother Albert and sister Grace, and many of her family and friends that have been patiently waiting for this joyous reunion.
  \nHere in life, and in our most cherished memories she is survived by her son Randolph and his wife (but whom she loved as a daughter) Margo, her granddaughters: Heidi Woodard, Richmond, CA, Tiffany and her husband Marcell Watson, Lake Charles, LA, Tenay Woodard-Hernandez and her husband Antonio Hernandez, San Pablo, CA: her great-grandsons Xavier Taylor-Burton who is attending Louisiana State University, 5th year Architecture,  Jerren Gilbert who is attending Louisiana Tech University; 1st year Engineering,  Ares Woodard Hernandez (6 yrs.), Major Watson (3 yrs.): her great granddaughter Arianna Hernandez (11 yrs.) and her nieces Joyce Brown and Evelyn Johnson, along with a host of cousins, relatives and friends.
  \nInez lived an amazing life, and through her life she was known by many names: Daughter, Sister, Cousin, Aunt, Wife, friend and Mama. Later in her life she was lovingly and affectionately known to her "Grands" and “Great-Grands” as Nana, and to her church family as Mother Rucker. But since the age of 12, when she was baptised, she knew that the one title, the most important title that saw her through her extraordinarily blessed life was the name  DAUGHTER OF THE KING!!
  \nInez was dearly loved by all and will be truly missed until we see her again. `;
  const storiesRecordUrl = "";
  const storiesSubmissionsUrl = "";
  const recordingEmbedDirectUrl = [
    {
      url: "https://storage.googleapis.com/afterword-static/recordings/Inez%20Woodard%20Rucker.mp4",
      title: "Memorial Recording",
    },
  ];
  const dropboxUrl = "";
  const pageFooter = (
    <section
      sx={{
        marginTop: ["0", "20px"],
      }}
    >
      <div className="aw-content">
        <Img
          title="Inez Woodard Rucker's Amazing Life"
          fluid={data.one.childImageSharp.fluid}
          sx={{
            margin: "0 auto",
          }}
        />
        <Img
          title="Inez Woodard Rucker's Amazing Life"
          fluid={data.two.childImageSharp.fluid}
          sx={{
            margin: "0 auto",
          }}
        />
        <Img
          title="Inez Woodard Rucker's Amazing Life"
          fluid={data.three.childImageSharp.fluid}
          sx={{
            margin: "0 auto",
          }}
        />
      </div>
    </section>
  );

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      zoomMeetingPasscode={zoomMeetingPasscode}
      inviteOverride={inviteOverride}
      customColumnSpacing="1fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      birthDeathFooter={birthDeathFooter}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
      recordingEmbedDirectUrl={recordingEmbedDirectUrl}
      dropboxUrl={dropboxUrl}
      pageFooter={pageFooter}
    ></StoryPage>
  );
};

export default ThisPage;

export const pageQuery = graphql`
  query {
    one: file(relativePath: { regex: "/inez-1.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    two: file(relativePath: { regex: "/inez-2.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    three: file(relativePath: { regex: "/inez-3.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
